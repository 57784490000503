import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "การเล่นบนสมาร์ทโฟน"
    }}>{`การเล่นบนสมาร์ทโฟน`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "สร้างบัญชีผู้ใช้และรหัสผ่าน"
    }}>{`สร้างบัญชีผู้ใช้และรหัสผ่าน`}</h3>
    <p>{`ผู้เล่นที่ต้องการสร้างบัญชีของตัวเกม สามารถสร้างได้จากหน้าแดชบอร์ด`}</p>
    <p><img alt="acc" src={require("./public/images/account-1.png")} /></p>
    <h3 {...{
      "id": "เข้าเล่นเกมด้วยบัญชีที่สร้าง"
    }}>{`เข้าเล่นเกมด้วยบัญชีที่สร้าง`}</h3>
    <p>{`สามารถใช้บัญชีของตัวเกม Login เข้าเล่นเกมในสมาร์ทโฟนได้`}</p>
    <p><img alt="acc" src={require("./public/images/account-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      